<template>
  <page :title="i18n.title" back>
    <div class="home">
      <div class="google-map">
        <google-map
          :locationList="list"
          :latLng="latLng"
          @center="onCenter"
          @zoom="onZoom"
          @setItem="onSetItem"
          @onmap="show = false"
        />
        <div class="home-left"></div>
        <div class="home-right"></div>
        <div class="home-bottom">
          <div class="nav-btn" @click="goRouter('/shop' + shopQuery)">
            <img
              class="nav-btn-pic"
              src="../../assets/battandgo/img_home_switch.png"
              alt=""
            />
          </div>
          <!-- <div class="nav-btn location" @click="goRouter('/help')">
            <img
              class="nav-btn-pic"
              src="../../assets/battandgo/img_home_location.png"
              alt=""
            />
          </div> -->
        </div>
        <transition name="fade">
          <div v-if="item && show" class="shop-info">
            <div class="shop-info-head">
              <div class="shop-title">
                <h3>{{ item.shopName }}</h3>
                <span
                  :class="[
                    item.infoStatus === '在线' ? 'shop-tag-active' : '',
                    'shop-tag'
                  ]"
                  >{{ item.infoStatus }}</span
                >
              </div>
              <a @click="goDetail"
                ><img src="@/assets/imgs/popup_btn_navigation.png" alt=""
              /></a>
            </div>
            <div class="shop-info-content">
              <div class="shop-logo">
                <img v-if="item.shopBanner" :src="item.shopBanner" alt="" />
                <img v-else src="../../assets/imgs/banner-home.jpg" alt="" />
              </div>
              <div class="shop-detail">
                <div class="shop-time">
                  <span class="time">
                    <img src="../../assets/imgs/map.png" alt="" />
                    {{ item.shopAddress1 }}
                  </span>
                  <span class="time">
                    <img src="../../assets/imgs/distance.png" alt="" />
                    {{ item.distance }}
                  </span>
                  <span class="time">
                    <img src="../../assets/imgs/icon_time.png" alt="" />
                    {{ item.shopTime }}
                  </span>
                </div>
                <div class="shop-op">
                  <span class="free-num"
                    >{{ i18n.free }}：{{ item.freeNum }}</span
                  >
                  <span class="return-num"
                    >{{ i18n.return }}：{{
                      Number(item.batteryNum) - Number(item.freeNum)
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
import GoogleMap from "../map/google-map.vue"
import { debounce } from "../../utils/index"
import { USER_INFO } from "../../apis/user"
export default {
  components: {
    GoogleMap
  },
  data() {
    return {
      latLng: [22.631123, 113.820622],
      zoomLevel: "4",
      list: [],
      item: null,
      show: false,
      isMenuView: false,
      ishomeSearch: false,
      isPickUpShow: false,
      searchText: "",
      isQrcodeLink: false
    }
  },
  computed: {
    ...mapState(["user", "token", "browser", "qrcode", "mificode", "urlcode"]),
    i18n() {
      return this.$t("map")
    },
    i18nHome() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    shopQuery() {
      const latLng = this.latLng || []
      return `?lat=${latLng[0]}&lng=${latLng[1]}`
    }
  },
  watch: {
    latLng() {
      this.lazyLoad(this)
    }
  },
  methods: {
    onClick() {
      console.log("未开放")
      this.$toast(this.i18nMsg.notopen)
    },
    goRouter(path) {
      this.$router.push(path)
    },
    reload(val) {
      console.log("reload===", val)
    },
    onZoom(zoomLevel) {
      // console.log('zoom', zoomLevel)
      this.zoomLevel = zoomLevel
      this.lazyLoad(this)
    },
    lazyLoad: debounce((vm) => {}, 500),
    onCenter(latLng) {
      this.latLng = latLng
      this.lazyLoad(this)
    },
    onSetItem(val = {}) {},
    goDetail() {
      console.log("show detail")
      this.$router.push({ path: "nearby_shop", query: this.item })
    },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (res) => {
          this.$loading(false)
          if (res.user) {
            this.$store.commit("user", res.user)
            if (this.isQrcodeLink) {
              this.$router.replace("/selectPakage?cabinetshow=true")
            } else {
              this.$router.push("/selectPakage?cabinetshow=true")
            }
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            })
              .then(() => {
                this.loadUser()
              })
              .catch(() => {
                this.$store.commit("token", null)
              })
          }
        },
        (err) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: err,
            showCancel: false
          }).then(() => {
            this.$store.commit("token", null)
          })
        }
      )
    }
  },
  created() {
    const { latitude = "", longitude = "" } = this.$route.query
    if (latitude && longitude) {
      this.latLng = [+latitude, +longitude]
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  // background: rgba(45, 45, 45, 0.5);
  height: 100%;
}
.home {
  position: relative;
  display: flex;
  .home-left {
    position: fixed;
    top: 56px;
    left: 20px;
    z-index: 1;
  }
  .home-right {
    position: fixed;
    top: 96px;
    right: 40px;
    z-index: 1;
  }
  .home-bottom {
    position: fixed;
    bottom: 75px;
    z-index: 1;
    width: 100%;
    .shop {
      float: right;
      margin-right: 40px;
      margin-top: -200px;
    }

    .nav-btn {
      position: absolute;
      left: 40px;
      bottom: 0;
      .nav-btn-pic {
        width: 100px;
      }
      &.location {
        left: initial;
        right: 40px;
      }
    }
  }
  .shop {
    justify-content: end;
  }
}
.google-map {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}
</style>
